import React from 'react'


export default function HubspotCta(props) {

    return(
        <>
            {/*HubSpot Call-to-Action Code */}
            <span
                className="hs-cta-wrapper"
                id={`hs-cta-wrapper-${props.ctaId}`}
            >
                <span
                className={`hs-cta-node hs-cta-${props.ctaId}`}
                id={props.ctaId}
                >
                {/*[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]*/}
                <a
                    href={props.url}
                    target="_blank"
                    rel="noopener"
                    className="btn"
                    title={props.title}
                >
                    {props.btnTxt}
                </a>
                </span>
            </span>
            {/* end HubSpot Call-to-Action Code */}
        </>
        )

    }
