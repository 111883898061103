import * as React from "react"
import {useState, useEffect} from "react"
import { Helmet } from "react-helmet"
import Slider from "react-slick";
import i18n from '../../components/i18n'
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import Layout from "../../components/layout"
import FeaturedTestimonial from "../../components/featured_testimonial"
import ServiceListBoxCard from "../../components/service_list_box_card"

import SocialConfData from "../../conf/social.json"
import Seo from '../../components/seo'
import NavigationHelper from "../../helpers/navigation";
import ShareButtons from "../../components/share_buttons"

import HubspotCta from "../../components/hubspot_cta"

// Images import
import img_service_testimonials_bg from "../../images/service-testimonials.jpg"

/** Partners images import */
import img_banner_aws from "../../images/aws_header_banner.png"
import img_logo_aws from "../../images/aws_badge_advanced.png"
import img_partner_logo_aws from "../../images/aws_partner_logo.png"

// Testimonial
import img_photo_man from "../../images/thumb_video_javelo.jpg"
import img_video_player from "../../images/cover_video_javelo.jpg"

// markup
const PartnerPage = (props) => {
const [activeLanguage, setActiveLanguage] = useState()
  useEffect(() => { setActiveLanguage(i18n.language); }, [setActiveLanguage])

  const { t } = useTranslation(['main', 'forms']);
  const ExtServiceCard = withTranslation('main')(ServiceListBoxCard)

  // Navigation info
  const pageInfo = {
    key: "partner",
    item_key: "amazon-web-services",
    lang: i18n.language
  }

  const mobile_offers_slider_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };

  //For the social share buttons
  const title = t('main:page-description_aws-partnership_meta_page_title' )
  const url = props.location.href;
  const twitterHandle = SocialConfData.ids.twitter;
  const tags = [
    t('main:page-description_aws-partnership_social_twitter_tag1_txt'),
    t('main:page-description_aws-partnership_social_twitter_tag2_txt'),
    t('main:page-description_aws-partnership_social_twitter_tag3_txt'),
    t('main:page-description_aws-partnership_social_twitter_tag4_txt')
  ];

  return (
    <Layout
      location={props.location}
      pageInfo={pageInfo}
    >
      <Helmet
        bodyAttributes={{
            class: 'partner-detail'
        }}
      >
          <title>{t('main:page-description_aws-partnership_meta_page_title' )}</title>
      </Helmet>

      <Seo
        description={t('main:page-description_aws-partnership_meta_seo_description_txt')}
        title={t('main:page-description_aws-partnership_meta_page_title' )}
        keywords={[
          t('main:page-description_aws-partnership_meta_seo_keyword1_txt'),
          t('main:page-description_aws-partnership_meta_seo_keyword2_txt'),
          t('main:page-description_aws-partnership_meta_seo_keyword3_txt'),
          t('main:page-description_aws-partnership_meta_seo_keyword4_txt'),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_services_in_a_box.webp`}
      />

    {/* breadcumbs */}
    <div className="site-breadcumbs">
      <div className="container">
        <small>
          <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'home')} title={t('main:global_header_home_menu-item-seo-title_txt')}>
            A1:
          </a>{" "}
          // {t('main:partners_header_breadcrumb_title')} / {t('main:aws-partnership_header_breadcrumb_title')}
        </small>
      </div>
    </div>
    {/* end breadcumbs */}

    <div
    className="blog-details-section nosrej_head partner_header"
    style={{ backgroundImage: `url(${img_banner_aws})` }}
  >
    <div className="container">
  <div className="title_block">
    <h1 className="nos-service">
    <Trans i18nKey="main:aws-partnership_header_block-title_html">
      Un partenariat <br />
      qui s'inscrit <br />
      dans la durée
    </Trans>
    </h1>
    {/* <h1 class="nos-service mobile_view"><span>fiche de poste<sub> #202106-002</sub> </span> consu- ltant(e) devSecOps</h1> */}
  </div>
</div>

  </div>

  <div className="service_detail nosrej_service blog-details-main-contan partner">
  <div className=" text-center desktop_view">
    <h2>{t('main:aws-partnership_main_catchphrase_txt' )}</h2>
  </div>
  <div className="container">
    <div className="block_box">
      <div className="partner-page-box-row first-box">
        <div className="partner-page-box-row-img">
          {/* <a href="#" title="info tip"> */}
            <div className="partner-page-box-row-img-first">
              <img className="partner_logo_img" src={img_logo_aws} alt="top-bg" />
            </div>
          {/* </a> */}
        </div>
        <div className="partner-page-box-row-text">
          {/* <h4 className="book-svg">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="folder-open"
              className="svg-inline--fa fa-folder-open fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"
              />
            </svg>{" "}
            Devops
          </h4> */}
          <h3>{t('main:aws-partnership_left-section_catchphrase_title' )}</h3>
          <Trans i18nKey="main:aws-partnership_left-section_catchphrase_html">
            <p>Mettez toutes les chances de votre côté en confiant vos projets Amazon Web Services à nos experts.</p>
          </Trans>
          <div className="cta-btn">
          <HubspotCta
                title={t('main:service-description_dtaas_cta_-tooltip_txt')}
                btnTxt={t('main:service-description_dtaas_cta_link')}
                ctaId="b3e6a794-c4e2-45c6-a1fa-9d7b680e85dc"
                url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
                />
          </div>



          <ul className="social_links">
              <b style={{ textTransform: 'uppercase'}}>{t('main:global_main_share_label')}</b>
              <ShareButtons
                title={title}
                url={url}
                twitterHandle={twitterHandle}
                tags={tags}
              />
            </ul>
        </div>
      </div>
    </div>
    <div className="middle_content">
      <div className="mobile_view">
        <h2>{t('main:aws-partnership_main_catchphrase_txt' )}</h2>
      </div>
      <Trans i18nKey="aws-partnership_main_intro_html">
      <p>
        Que vous soyez une start-up en phase de démarrage, une scale-up en pleine expansion ou une grande entreprise à la dimension internationale, A1 Cloud vous aidera à tirer le meilleur parti du riche écosystème proposé par Amazon Web Services.
      </p>
      <p>
        C'est cette ambition d'apporter le meilleur service à nos clients qui nous a amené à développer un partenariat avec le fournisseur de solutions robustes et de pointe qu'est AWS.
      </p>
      </Trans>
      <p align="center" class="partner_centered_logo">
        <a href="https://partners.amazonaws.com/partners/0010h00001jCcZaAAK/" target="_blank" title="Voir la page A1 Cloud sur AWS Partner Network (nouvelle fenêtre)">
          <img src={img_partner_logo_aws}/>
        </a>
      </p>
      <Trans i18nKey="aws-partnership_main_content_html">
      <p>
        Nos ingénieurs se forment continuellement sur les services Cloud d'AWS et maintiennent régulièrement leur niveau de certification. Leurs expertises seront précieuses pour vous accompagner, entre autres, dans les domaines suivants:
      </p>
      </Trans>
    </div>
  </div>
</div>

<div className="service-page-main-contant">
    <div className="container">
      <div className="middle_content">
        <h3>{t('main:aws-partnership_domains-section_title' )}</h3>
        <p className="intro">
          <Trans i18nKey="aws-partnership_domains-section_intro_txt">
          Nos ingénieurs sont là pour vous accompagner sur vos projets.<br/>Ci-dessous quelques domaines dans lesquels nous pouvons intervenir à vos côtés sur Amazon Web Services.
          </Trans>
          </p>
      </div>
      <div className="row new-mobile-slider desktop_view justify-content-center">

        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="cloud"
            type="aws"
            category_label={t('main:services_main_cloud-category_title' )}
            service_name={t('main:aws-partnership_domains-section_service-bdd-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_service-bdd-offer_title' )}
            service_name_fragment_2=""
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_service-bdd-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_service-bdd-offer-block-desc_txt' )}
          />
        </div>

        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="cloud"
            type="aws"
            category_label={t('main:services_main_cloud-category_title' )}
            service_name={t('main:aws-partnership_domains-section_migration-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_migration-offer_p1' )}
            service_name_fragment_2={t('main:aws-partnership_domains-section_migration-offer_p2' )}
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_migration-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_migration-offer-block-desc_txt' )}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="sre"
            type="aws"
            category_label={t('main:services_main_sre-category_title' )}
            service_name={t('main:aws-partnership_domains-section_storage-and-dr-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_storage-and-dr-offer_p1' )}
            service_name_fragment_2={t('main:aws-partnership_domains-section_storage-and-dr-offer_p2' )}
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_storage-and-dr-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_storage-and-dr-offer-block-desc_txt' )}
          />
        </div>

        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="devops"
            type="aws"
            category_label={t('main:services_main_devops-category_title' )}
            service_name={t('main:aws-partnership_domains-section_workload-containerization-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_workload-containerization-offer_p1' )}
            service_name_fragment_2={t('main:aws-partnership_domains-section_workload-containerization-offer_p2' )}
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_workload-containerization-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_workload-containerization-offer-block-desc_txt' )}
          />
        </div>
      </div>
      <div className="new-mobile-slider mobile_view mobile_slider">
      <Slider {...mobile_offers_slider_settings}>

        <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="cloud"
            type="aws"
            category_label={t('main:services_main_cloud-category_title' )}
            service_name={t('main:aws-partnership_domains-section_service-bdd-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_service-bdd-offer_title' )}
            service_name_fragment_2=""
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_service-bdd-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_service-bdd-offer-block-desc_txt' )}
          />
        </div>

        <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="cloud"
            type="aws"
            category_label={t('main:services_main_cloud-category_title' )}
            service_name={t('main:aws-partnership_domains-section_migration-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_migration-offer_p1' )}
            service_name_fragment_2={t('main:aws-partnership_domains-section_migration-offer_p2' )}
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_migration-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_migration-offer-block-desc_txt' )}
          />
        </div>

        <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="sre"
            type="aws"
            category_label={t('main:services_main_sre-category_title' )}
            service_name={t('main:aws-partnership_domains-section_storage-and-dr-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_storage-and-dr-offer_p1' )}
            service_name_fragment_2={t('main:aws-partnership_domains-section_storage-and-dr-offer_p2' )}
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_storage-and-dr-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_storage-and-dr-offer-block-desc_txt' )}
          />
        </div>

        <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="devops"
            type="aws"
            category_label={t('main:services_main_devops-category_title' )}
            service_name={t('main:aws-partnership_domains-section_workload-containerization-offer_title' )}
            service_name_fragment_1={t('main:aws-partnership_domains-section_workload-containerization-offer_p1' )}
            service_name_fragment_2={t('main:aws-partnership_domains-section_workload-containerization-offer_p2' )}
            service_name_fragment_3=""
            service_name_fragment_4=""
            link_seo_title={t('main:aws-partnership_meet-expert_seo-title_link',{"service_name": t('main:aws-partnership_domains-section_workload-containerization-offer_title' ).toLowerCase()})}
            link_seo_btn={t('main:global_main_meet-expert_link')}
            link_target_url="https://meetings-eu1.hubspot.com/a1cloud-maa/a1cloud-skills-on-aws"
            link_type="external"
            service_description={t('main:aws-partnership_domains-section_workload-containerization-offer-block-desc_txt' )}
          />
        </div>
      </Slider>
      </div>
    </div>
  </div>

<div
    className="service_testimonial s_detail"
    style={{ backgroundImage: `url(${img_service_testimonials_bg})`}}
  >
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="sec-title">{t('main:title_your_testimonials')}</h1>
        </div>
        <FeaturedTestimonial
          testimonial_headline={t('main:testimonials_main_javelo_title')}
          testimonial_description={t('main:testimonials_main_javelo_txt')}
          thumb_alt={t('main:single-testimonial_main_javelo-interviewee-name_txt')}
          thumb_pic={img_photo_man}
          video_preview_pic={img_video_player}
          seo_link_title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "Javelo" })}
          testimonial_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial','javelo')}
        />
      </div>
    </div>
  </div>

    </Layout>
  )
}

export default PartnerPage
